.projects-container {
  background-color: transparent;
}

.projects-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.projects-card h3 {
  color: #f6f6f6;
  margin: 0;
}

.projects-card p {
  margin: 0;
  line-height: 1.4; 
}

.projects-card {
  text-decoration: none;
  border-radius: 5px;
  flex: 1;
  min-width: 250px;
  color: #999999;
  padding: 10px;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: flex-start; 
  transition: background 0.3s ease, box-shadow 0.3s ease; 
}

.projects-card .language {
  width: 25%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start; 
  padding-top: 6px;
  color: #23c8a1;
  font-size: large;
}

.projects-card .details {
  width: 75%;
  display: flex;
  flex-direction: column;
}

.projects-card:hover {
  background: rgba(153, 153, 153, 0.3);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
}
