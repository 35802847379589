/* Home.css */
/* Mobile view adjustments */
@media (max-width: 768px) {
  .home-container {
    flex-direction: column;
  }

  .content-container {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    flex-direction: column;
    align-items: left;
    gap: 1rem;
  }

  .menu {
    width: 100% !important; 
    padding: 0rem !important;
    padding-top: 100px !important; 
    position: static !important;
    margin-bottom: 2rem; 
  }

  .menu h1, h2 {
    text-align: left;
  }

  .menu ul {
    display: flex;
    flex-direction: column;
    align-items: left;
  }

  .menu ul li {
    margin: 0.5rem 0; 
  }

  .menu a {
    padding: 0.5rem 1rem; 
  }

  .content {
    width: 100% !important;
    overflow: hidden;
  }

  .content section {
    padding-top: 1rem; 
    padding-bottom: 1rem;
  }
}

.home-wrapper {
  position: relative;
  z-index: 1;
}

.particles-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.home-container {
  display: flex;
  width: 100vw;
  position: relative;
  z-index: 1;
}

.content-container {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 6rem;
  padding-right: 6rem;
  max-width: 1280px;
  min-height: 100vh;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  gap: 4rem;
  justify-content: space-between;
}

.menu {
  width: 50%; 
  background-color: transparent; /* Fully transparent background */
  padding-top: 100px;
  position: sticky;
  justify-content: space-between;
  flex-direction: column;
  max-height: 75vh;
  display: flex;
  top: 0;
}

.menu h1, h2 {
  color: #f6f6f6;
}

.menu a {
  text-decoration: none;
  color: #c7c7c7;
}


.menu a:hover {
  color: #f6f6f6;
}

.menu h1, h2 {
  margin-top: 0;
}

.menu ul {
  list-style: none;
  padding: 0;
}

.menu ul li {
  margin: 10px 0;
}

.menu ul li a {
  text-decoration: none;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  align-items: center;
  display: flex;
}

.summary-text {
  max-width: 20rem;
}

/* Initial state for the line and text */
.bullet-line {
  width: 4rem; /* Initial width */
  --tw-bg-opacity: 1;
  background-color: #c7c7c7;
  transition-property: width, background-color;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-duration: .15s;
  height: 1px;
  margin-right: 1rem;
}

.bullet-text {
  --tw-text-opacity: 1;
  letter-spacing: .1em;
  font-weight: 700;
  line-height: 1rem;
  transition: color 0.15s cubic-bezier(.4,0,.2,1);
  text-decoration: none; /* Remove underline */
}

/* Hover state for expanding line and changing color */
ul li:hover .bullet-line {
  width: 6rem; 
  background-color: #23c8a1 
}

ul li:hover .bullet-text {
  color: #23c8a1
}

/* Active state to persist changes */
ul li.active .bullet-line {
  width: 6rem;
  background-color: #23c8a1 
}

ul li.active .bullet-text {
  color: #23c8a1 
}

.socials {
  flex-direction: row;
  display: flex;

  gap: 1rem;

}

.social-icon {
  width:30px;
}

.email-icon {
  width:32px !important;
}

.mobile-icon {
  width: 22px !important;
  height: 30px;
  padding-top: 1px;
}

.content {
  width: 50%;
  height: 100%;
}

.content section {
  padding-top: 100px; 
  padding-bottom: 50px; 
}

.content section h2 {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}
